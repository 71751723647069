import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function EmpathyMap(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(93, 72, 149, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Empathy Map</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          The empathy map is a visual tool that allows you to build a stakeholder profile by quickly browsing the sources of information you have close at hand.
          The empathy map is intended for you to put yourself into a stakeholder's shoes and thereby see the challenge from a different perspective.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          When you have carried out an identification of your potential stakeholders and want to know more about them and don’t have the time or the resources to
          carry out in-depth market research.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          The main value of this tool is how quickly you can get a clear and pretty accurate profile of a stakeholder.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Empathy-Map_Virtual-Hackathon.jpg")} alt='Empathy map image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Empathy-Map_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Empathy-Map_Virtual-Hackathon.jpg"), alt: 'Empathy map image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              20 min each stakeholder.
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1. Setting the scene (10 min)</span>

        <p>
          Write down the name and a short description on the specific stakeholder to be described and put it on the upper right-hand side of the canvas. Then, pay
          attention to the challenge you are working on. Write the problem down on a post-it and put it on the lower right-hand side of the canvas. Finally, look at
          the stakeholder and how he/she relates to the challenge. Is she a prospect, a client, a user of your service/product? Is she affected by your project? Or,
          can she affect the process of developing a new solution? If so, in which way? Describe this relation on a post-it and place it between the stakeholder and
          challenge description. These three notes will provide the context for the rest of the tool.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2. Thinking and responding (10 min)</span>

        <p>
          Next, the group starts trying to fill out those nine areas by responding on sticky cards to the questions linked to them. In the following paragraphs you can
          find a list of questions that you can use as a guideline to find your answers.
        </p>

        <div className='px-3'>
          <span className='h6 d-block font-weight-bold mb-2'>THINK (brain)</span>

          <p>
            "What does he really care about?", "What is her endgame/ deep belief?", "What do they think about the challenge and the current market solution?", "How do they
            think about their fears and hopes?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>SEE (eyes)</span>

          <p>
            "What do they see when they face the problem/challenge in their daily life?", "What TV programs does she watch?", "What is the context/environment they see around
            them?", "What technology/ solutions does the market offer?", "What does a typical day look like in their world?"...
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>HEAR (ears)</span>

          <p>
            "What do their friends/boss relatives... say?", "What influencers do they follow and what do they say?", "Who does he really listen to?", (radio, forums, social
            media...), "What do they hear when other people use the same technology or face the same problem?", "Is she following the big players?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>SAY (mouth)</span>

          <p>
            "What does she say regarding the challenge in a conversation?", “Is he inspired by an inspirational idea when talking about the problem?", "What do they say when
            using the current technology?", "What opinions do they state about innovative solutions?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>FEEL (heart)</span>

          <p>
            "What do they feel when using the technology, whether in private or public?", "What are his feelings regarding the players in the market and society, related to
            the challenge?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>DO (arms/hands)</span>

          <p>
            "What is their attitude in public when it comes to interacting with the technology or problem?, "What is her behaviour when using the current solutions?", "Is he
            trying to do anything to defy or modify the status quo?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>PAIN (back)</span>

          <p>
            "What are the barriers they face in their day-to-day life?", "What are their pain points when using the current solution?", "What are their concerns about new
            solutions and future changes?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>GAIN (legs)</span>

          <p>
            "What do they really want from the technology?", "What are her actual needs?", "How do they measure success?" "What are his expectations regarding the problem in terms
            of solutions and general environment?"
          </p>

          <span className='h6 d-block font-weight-bold mb-2'>BELIEVE (feet)</span>

          <p>
            "What do they actually believe?", "What are their thoughts rooted in?", "What are their implicit and explicit assumptions about the challenge? (technology, how society
            reacts...)"
          </p>

          <p>
            When you look at the completed canvas, spend some minutes reflecting on the process and the outcome.
          </p>
        </div>

        <h3 className='h5 mt-5 mb-4'>PRACTICAL TIPS</h3>
        <ul>
          <li>This is a technique that can be enriched with others such as stakeholder interviews, shadowing, market analysis, etc.</li>
          <li>When searching for information try to find out what other stakeholders think about the one you are working on. It might make you change your opinion and answers.</li>
          <li>
            If there are opposite answers in some areas, keep them on the canvas, bear in mind human complexity and the fact that sometimes both answers might be right under specific
            circumstances.
          </li>
        </ul>
      </div>
    </Page>
  );
}