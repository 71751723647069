import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';

export default function Ideation(props) {
  return (
    <Page background="rgba(46, 134, 222, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />
        
        <h1 className='main-heading mb-5'>Ideation</h1>

        <p>
          The ideation activity is like any other brainstorming activity, project teams encourage and harness the creativity of individuals to turn it
          into collective intelligence, more effective than the sum of its parts. Ideation is about releasing and harvesting individual creativity and
          turning it into collective intelligence. The ideation stage comprises the phases of generating, evaluating and improving ideas, and also involves
          fostering healthy creative friction, being able to integrate different perspectives.
        </p>

        <div className='row justify-content-center mt-5'>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/ideation/brainstorming',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Brainstorming.png")} alt='Brainstorming image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/ideation/analysis',
                state: { prev: false },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Analysis.png")} alt='Analysis image' />
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}