import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';

export default function MakingSense(props) {

  return (
    <Page background="rgba(93, 72, 149, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Making Sense</h1>

        <p>
          Making sense is about creating a deep awareness of the context, and the ability to embrace diversity and complexity. This full awareness
          is needed to understand the system as a whole and clearly define the right challenge to work with later.
        </p>
        <p>
          First, it is necessary to be aware of the social, technological, economic, and environmental context where the challenge is embedded. It
          goes beyond the mere depiction of the systems and seeks a deep understanding of underlying dynamics and relationships that bring about emergent
          behaviours and characteristics. The purpose of contextual awareness is to reveal the system to itself. Without this understanding of the hidden
          dynamics one solution for an apparently simple problem quite often leads to new issues and unexpected consequences. In the face of this complexity,
          the first step is to embrace diversity as the leverage point to get a more comprehensive understanding of the reality. The assertion that every
          voice in the systems carries part of the truth becomes highly relevant and should guide the sense making stage.
        </p>
        <p>
          This awareness of the context entails perceiving the system as a wholeness made up of interconnected and interdependent parts. The attention must be
          diverted from the parts towards the relationships to see the whole picture and the emergent patterns.
        </p>
        <p>
          Even more critical is the internal awareness of being part of the system and not an external agent. This is all about becoming aware of the systems and
          subsystems each individual and organization is part of, and the role being played. Finally, this internal awareness has to do with identifying and understanding
          the mental models and values that shape the way individuals and organizations perceive and interpret the reality.
        </p>
        <p>
          The goal of this stage is becoming aware of the complex context surrounding the issue at hand, including analysing, and synthesizing how stakeholders
          experience the system. This context is not a fixed picture of components but rather a dynamic reality which is being continuously shaped by the intricate
          network of stakeholders and the relationships among them. Because of those relationships, the environment, the economy, the technology, or the society as
          such can be massively impacted and transformed. The objective is to understand this complexity.
        </p>

        <div className='row justify-content-center mt-5'>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/making-sense/pentagonal-problem',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Pentagonal-Problem.png")} alt='Pentagonal problem image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/making-sense/empathy-map',
                state: { prev: false },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Empathy-Map.png")} alt='Empathy map image' />
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}