import React from 'react';
import { useHistory } from 'react-router-dom';

export default function BackButton() {
    const history = useHistory();
  
    return (
        <button
            type="button"
            className="btn btn-link text-decoration-none mb-4 p-0"
            onClick={history.goBack}
        >
            <em className="d-inline-block me-1 bi-arrow-return-left"></em> Go back
        </button>
    );
}