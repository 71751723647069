import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function PentagonalProblem(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(93, 72, 149, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Pentagonal Problem</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          Pentagonal Problem is a visual tool to help teams nail down the problem, identify its different components and details, agreeing on a common
          ground for future actions.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          Whenever you face a complex problem, with multiple sides, perspectives and degrees that make it difficult to define in a single sentence or paragraph.
          Challenges related to climate change are a clear example of this kind of problem.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          System innovation entails a completely different approach in the way we define and address problems. Problems are no longer simple or isolated. Instead,
          they can affect a big number of stakeholders with different perceptions and interests, they are cross-sectorial, long-term, and interconnected with the
          ecosystem and societal structures.
        </p>
        <p>
          In this context we need more comprehensive tools to better define, state and understand current problems. Pentagonal problem is a tool that starts with
          your own perspective of the problem, and helps you to deepen your understanding by including different aspects of it. Using this tool, you will be better
          prepared to look for system solutions.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Pentagonal-Problem_Virtual-Hackathon.jpg")} alt='Pentagonal problem image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Pentagonal-Problem_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Pentagonal-Problem_Virtual-Hackathon.jpg"), alt: 'Pentagonal problem image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              The pentagonal problem is a very easy-going technique. The extension in a classroom can vary from 1 to 2 hours, and its preparation is also very quick,
              just print the canvas and prepare markers and post-its.
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1. Define yourself and state your problem. (10 min)</span>

        <p>
          The pentagon accounts for yourself (as an individual or team). Take into account that the same problem is perceived in different ways and shapes by different
          actors, therefore it is essential to start by defining yourself. After that, try to describe the problem in one single sentence or short paragraph. Try to be
          conversational within the group. The goal here is to describe the overall problem or challenge you are facing as if you were in a conversation with other
          colleagues. Avoid any piece of information that is not necessary to understand the “big picture” problem. Once you have described yourself and state your problem,
          it is time to define the problem through five different "faces".
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2. Visible Consequences (10-20 min)</span>

        <p>
          In this step, it is important to write down all the elements which have been described during the interviews or have come up during the previous approach to the
          problem. It is important that the participants are aware of the real and visible implications of the problem.  Use post-it notes and write down all the ideas.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 3. Long Term Drivers (10-20 min)</span>

        <p>
          Now it is time to specify the climate change related challenges or other long term drivers that your problem is facing. Pay attention to the problems with CO2
          emissions, water scarcity, biodiversity or whatever they are. In the case of many participants you can make clusters with the notes and identify the main clouds
          of long term drivers.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 4. Social Challenges (10-20 min)</span>

        <p>
          Now it is time to think of society and how it is affected by the problem. Is societal behaviour worsening the problem or it is getting it better? What are the
          societal challenges underlying your project? What is the main expected or needed change? What are the visible bottlenecks? Are there any specific groups especially
          affected by the problem or having a significant effect on it? Are there any institutions or organizations playing a significant role? What direction are the
          regulations pushing towards? Using post-its, write down as many ideas as possible to get the most comprehensive picture. If necessary, cluster the notes and identify
          the main clouds of societal challenges.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 5. Socio-Technical Alternatives (10-20 min)</span>

        <p>
          In this step, it is important to rescue all the possible solutions that you have found during the previous weeks. Search for solutions already implemented in other
          locations or ideas that came up during the interviews. It is not the moment to evaluate them or see the real application in your landscape. Just write down as many
          ideas as possible and cluster them in different topics or type of solutions.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 6. Dominant solutions (10-20 min)</span>

        <p>
          When we think about the status-quo of our system, several elements act as dominant solutions which avoid change and transition to more sustainable ones. Identifying
          these elements will help us to plan future actions and future allies in our proposals. It is also interesting to define them in order to understand the whole system.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 7 Debrief (10-20 min)</span>

        <p>
          Once you have completed the pentagonal description of your project, go over the first problem statement and how it has been enriched with the inputs coming from very
          different sources. Do you think you got a thorough description of your challenge? Did you get a new understanding of your problem? In your description, do you feel you
          included more than necessary? Would it be possible and advisable to cut something out in order to better explain the problem? Or, do you think you are still leaving
          something out?
        </p>

        <p>
          Do you think your challenge is a technical problem, a social problem, an environmental problem... or a mixture of them? Does one of the “faces” seem more important than
          the others? Regarding the variety of inputs, do you consider it important to gather different perspectives about the challenge?
        </p>

        <p>
          Now try to rephrase the problem statement taking into consideration all the inputs you obtained. Are you able to come to a consensus for the new definition? Is it easier
          or more difficult to broaden such a definition? Do you consider it is possible to get everybody committed with a new definition of the problem?
        </p>

        <h3 className='h5 mt-5 mb-4'>PRACTICAL TIPS</h3>
        <ul>
          <li>Try to be casual when it comes to defining the problem, avoid overly complex sentences.</li>
          <li>The more ideas you gather for each step; the richer the final vision of your problem will be.</li>
          <li>
            Time permitting, after filling in each cell, you can try to restate the central problem, taking
            into consideration the ideas on the post-its. This will give you a perspective of how the problem
            is changing as new inputs are included, and you will experience the difficulties of integrating
            different approaches and inputs.
          </li>
        </ul>
      </div>
    </Page>
  );
}