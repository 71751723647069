import React from 'react';
import { NavLink, useLocation } from "react-router-dom";

export default function Nav() {
  let location = useLocation();

  return (
    <header className='fixed-top text-center pt-2 pt-md-0'>
      <NavLink 
        to={{
          pathname: '/',
          state: (location.pathname == '/') ? {prev: true} : {prev: false},
        }} 
        className="d-md-none"
      >
        <img className='img-fluid' width="140" src={require("../assets/img/logo.png")} alt='Favicon image' />
      </NavLink>

      <nav className="nav navbar justify-content-center">
        <NavLink 
          to={{
            pathname: '/',
            state: (location.pathname == '/') ? {prev: true} : {prev: false},
          }} 
          className="nav-link d-none d-md-inline-block"
        >
          <img className='img-fluid' src={require("../assets/img/logo.png")} alt='Logo image' />
        </NavLink>
        <NavLink 
          to={{
            pathname: '/',
            state: (location.pathname == '/') ? {prev: true} : {prev: false},
          }} 
          className="nav-link"
        >
          Home
        </NavLink>
        <NavLink 
          to={{
            pathname: '/making-sense',
            state: (location.pathname == '/making-sense') ? {prev: true} : {prev: false},
          }}
          className="nav-link"
        >
          Making Sense
        </NavLink>
        <NavLink 
          to={{
            pathname: '/framing',
            state: (location.pathname == '/framing') ? {prev: true} : {prev: false},
          }}
          className="nav-link"
        >
          Framing
        </NavLink>
        <NavLink 
          to={{
            pathname: '/ideation',
            state: (location.pathname == '/ideation') ? {prev: true} : {prev: false},
          }}
          className="nav-link"
        >
          Ideation
        </NavLink>
        <NavLink 
          to={{
            pathname: '/solution',
            state: (location.pathname == '/solution') ? {prev: true} : {prev: false},
          }}
          className="nav-link"
        >
          Solution
        </NavLink>
      </nav>
    </header>
  );
}