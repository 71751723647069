import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';

export default function Solution(props) {
  return (
    <Page background="rgba(16, 172, 132, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />
        
        <h1 className='main-heading mb-5'>Solution</h1>

        <p>
          Once we have chosen an idea, it is important to ensure that our solution creates value for the system. Understanding value, benefits, costs,
          users or beneficiaries as something beyond the chrematistic perspective is also part of this mindset.
        </p>
        <p>
          In this area, we work on developing the idea and learning how to communicate it.
        </p>

        <div className='row justify-content-center mt-5'>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/solution/value-proposition',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Value-Proposition.png")} alt='Value Proposition image' />
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}