import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';

export default function Framing(props) {
  return (
    <Page background="rgba(16, 172, 132, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />
        
        <h1 className='main-heading mb-5'>Framing</h1>

        <p>
          The ultimate goal of the framing stage is to define the challenge to be addressed by the innovation team. The challenge emerges from the system,
          it being the stakeholders who ultimately define what the challenge to be addressed is. Additionally, that challenge is not defined in terms of
          something to be fixed but as a direction of change or systems’ aspiration, a system to be transformed by means of innovative interventions. 
        </p>
        <p>
          Part of the framing stage is also setting the boundaries of the challenge to be defined. For example, if the overall challenge to address is the
          climate change, this is boundless by its own conception. The plethora of interlinkages with social, economic, environmental or technological
          elements makes it barely possible to be addressed in its wholeness. Instead, the boundaries for a specific innovation process have to be defined
          so as to the challenge can be more easily addressed. These boundaries can be geographic, topic oriented (droughts, mobility, housing...) or any
          other nature that help teams to frame the challenge.
        </p>

        <div className='row justify-content-center mt-5'>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/framing/innovation-focus',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Innovation-Focus.png")} alt='Innovation Focus image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/framing/triple-i',
                state: { prev: false },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../../assets/img/Triple.I.png")} alt='Triple I image' />
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}