import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function InnovationFocus(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(16, 172, 132, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Innovation Focus</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          The innovation focus is a more detailed definition of the challenge that the team wants to address during the hackathon. It will be linked to the
          context analysis the team has performed in the previous step of the process. 
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          When you want to detail and go further in the understanding of the challenge in order to approach the ideation process from a more concrete point of
          view, this tool will facilitate the following steps.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          This tool will help us because the clearer we are about the challenge and the areas in which we want to have an impact, the easier it will be to carry
          out the ideation process.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg")} alt='Innovation focus image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg"), alt: 'Innovation focus image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              30 minutes.
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <p>Write down at least 8 focuses by defining in each case 4 characteristics:</p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1. Transformation</span>

        <p>
          Think about the transformation that answering this question can imply in the system.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2. Stakeholders</span>

        <p>
          Who is involved in this innovation?
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 3. Needs</span>

        <p>
          Think about needs that the system (pentagonal problem) or the stakeholders (empathy map) have.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 4. Redefinition of the challenge</span>

        <p>
          State the innovation focus by a question starting by "How come... we...?. We can have several focuses for one need.
        </p>

        <h3 className='h5 mt-5 mb-4'>PRACTICAL TIPS</h3>
        <ul>
          <li>It will not be necessary to fill in all the gaps.</li>
        </ul>
      </div>
    </Page>
  );
}