import React from 'react';
import { render } from 'react-dom';
import { 
  Route, 
  Switch, 
  BrowserRouter,
} from 'react-router-dom';
import { 
  CSSTransition, 
  TransitionGroup 
} from 'react-transition-group';

import './assets/css/style.css';
// import './assets/css/bootstrap.min.css';
// import './assets/css/bootstrap-icons.min.css';

import Nav from './components/Nav';
import Home from './pages/Home';
import MakingSense from './pages/MakingSense';
import EmpathyMap from './pages/MakingSense/EmpathyMap';
import PentagonalProblem from './pages/MakingSense/PentagonalProblem';
import Framing from './pages/Framing';
import InnovationFocus from './pages/Framing/InnovationFocus';
import TripleI from './pages/Framing/TripleI';
import Ideation from './pages/Ideation';
import Brainstorming from './pages/Ideation/Brainstorming';
import Analysis from './pages/Ideation/Analysis';
import Solution from './pages/Solution';
import ValueProposition from './pages/Solution/ValueProposition';
import Error404 from './pages/Error404';

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = 'pushState' in window.history;

const App = ({  }) => (
  <BrowserRouter forceRefresh={!supportsHistory}>
    <div>
      <Nav />
      <main>
        <Route
          render={({ location }) => {
            const { pathname } = location;
            return (
              <TransitionGroup>
                <CSSTransition 
                  key={pathname}
                  classNames="page"
                  timeout={{
                    enter: 1000,
                    exit: 1000,
                  }}
                >
                  <Route
                    location={location}
                    render={() => (
                      <Switch>
                        <Route
                          exact
                          path="/"
                          component={Home}
                        />
                        <Route
                          path="/making-sense/pentagonal-problem"
                          component={PentagonalProblem}
                        />
                        <Route
                          path="/making-sense/empathy-map"
                          component={EmpathyMap}
                        />
                        <Route
                          path="/making-sense"
                          component={MakingSense}
                        />
                        <Route
                          path="/framing/innovation-focus"
                          component={InnovationFocus}
                        />
                        <Route
                          path="/framing/triple-i"
                          component={TripleI}
                        />
                        <Route
                          path="/framing"
                          component={Framing}
                        />
                        <Route
                          path="/ideation/brainstorming"
                          component={Brainstorming}
                        />
                        <Route
                          path="/ideation/analysis"
                          component={Analysis}
                        />
                        <Route
                          path="/ideation"
                          component={Ideation}
                        />
                        <Route
                          path="/solution/value-proposition"
                          component={ValueProposition}
                        />
                        <Route
                          path="/solution"
                          component={Solution}
                        />
                        <Route
                          component={Error404}
                        />
                      </Switch>
                    )}
                  />
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </main>
    </div>
  </BrowserRouter>
);

render(<App />, document.getElementById('root'));
