import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function Analysis(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(46, 134, 222, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Analysis</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          It is a visual tool that helps us to filter and choose which of the ideas we have had during the brainstorming process we want to develop. By using
          two matrices (feasibility and transformative) we will detect the potential impact of our ideas and it will help us to choose the one that best fits
          these requirements.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          After the creative brainstorming process and before the development of the solution.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          Because it will facilitate decision making. By placing the ideas on different scales according to their feasibility and transformational capacity, we
          will be able to detect the level of both variables that our ideas have and choose the one that can generate the most impact.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Analysis_Virtual-Hackathon.jpg")} alt='Analysis image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Analysis_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Analysis_Virtual-Hackathon.jpg"), alt: 'Analysis image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              1 hour.
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1: ANALYSIS (25 min)</span>

        <p>
          Make a first analysis of the ideas that came out of the brainstorming and group those that are the same or very similar into the same idea.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2: PLACE THE IDEAS (25 min)</span>

        <p>
          Using the tool, place the ideas that have emerged from the ideation process in the two variables: Viability and transformative.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 3: DECISION (10 min)</span>

        <p>
          As a group and taking into account the results of the matrix, decide which of the ideas you want to develop.
        </p>
      </div>
    </Page>
  );
}