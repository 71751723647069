import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function Brainstorming(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(46, 134, 222, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Brainstorming</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          Brainstorming is a group creativity technique used to find a solution to a specific problem. This is achieved by gathering and recording new ideas
          from team members in a fluid way.
        </p>

        <p>
          This activity helps to foster and harness the creativity of individuals into collective intelligence, which is more effective than the sum of its parts.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          This tool should be used once we have a clear focus and understanding of the challenge we are going to face.  In addition, it is important to ensure that
          we get the team to work in an environment that helps to foster creativity.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          Brainstorming allows people to think more freely, without fear of judgement. It encourages open and continuous collaboration to solve problems and generate
          innovative ideas and helps teams generate a wealth of ideas quickly, which can be refined and merged to create the ideal solution.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Brainstorming_Virtual-Hackathon.jpg")} alt='Brainstorming image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Brainstorming_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Brainstorming_Virtual-Hackathon.jpg"), alt: 'Brainstorming image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              2 hours.
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <p>
          The brainstorming activity has to steps: Diverging and Converging and they can be repeated several times in a loop process.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1: Diverging: Brainstorming (30-45 min)</span>

        <p>
          In this part of the process, the team has to write down all the ideas they can imagine to address the innovation focus. You have to follow these four rules:
        </p>

        <ul>
          <li>Do not judge any idea</li>
          <li>It is about quantity not quality. Write down as many ideas as you might have</li>
          <li>Be creative, crazy ideas are welcome.</li>
          <li>Read other people ideas and build upon them.</li>
        </ul>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2: Converging: Organizing the ideas (60-90 min)</span>

        <p>
          In this part, the team organizes the ideas using a matrix with two axis (transformative and feasibility). You have to follow these rules:
        </p>

        <ul>
          <li>Improve the ideas</li>
          <li>Be affirmative</li>
          <li>Consider novelty</li>
          <li>Think about your innovation focus</li>
        </ul>
      </div>
    </Page>
  );
}