import React from 'react';
import Page from '../components/Page';
import Link from 'react-router-dom/Link';

export default function Home() {
  return (
    <Page>
      <div className='w-xl-80 mx-auto'>
        <h1 className='main-heading mb-5'>Roadmap</h1>

        <p>
          Universities in Europe have been confronted with a sudden and rather unprepared shift towards online teaching due to the Covid19 pandemic.
          New tools are needed to embrace digital solutions as opportunities for collaborations and innovation processes, also beyond times of social
          distancing.
        </p>
        <p>
          The Virtual Hackathon method offers a practical approach to tackle this rising need. This document is a toolkit to accompany the participant
          during the hackathon.
        </p>
        
        
        <div className='row row-cols-2 justify-content-center mt-5'>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/making-sense',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../assets/img/01-Making-Sense.png")} alt='Making sense image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center mb-4 mb-md-0'>
            <Link 
              to={{
                pathname: '/framing',
                state: { prev: true },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../assets/img/02-Framing.png")} alt='Framing image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center'>
            <Link 
              to={{
                pathname: '/ideation',
                state: { prev: false },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../assets/img/03-Ideation.png")} alt='Ideation image' />
            </Link>
          </div>
          <div className='col-6 col-md-3 text-center'>
            <Link 
              to={{
                pathname: '/solution',
                state: { prev: false },
              }}
            >
              <img className='img-fluid rounded-3' src={require("../assets/img/04-Solution.png")} alt='Solution image' />
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}