import React from 'react';
import Page from '../../components/Page';
import BackButton from '../../components/BackButton';
import Viewer from 'react-viewer';

// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

export default function TripleI(props) {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <Page background="rgba(16, 172, 132, 0.2)">
      <div className='w-xl-80 mx-auto'>
        <BackButton />

        <h1 className='main-heading mb-5'>Triple I</h1>

        <span className='h6 d-block font-weight-bold mb-2'>WHAT IT IS</span>

        <p>
          It is a voting procedure for helping to decide which element of a list could be more interesting to pursue.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHEN TO USE</span>
        <p>
          This tool should be used whenever there is a need to focus the challenge and focus the ideation phase.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>WHY IT IS USEFUL</span>
        <p>
          This tool will be useful to facilitate the dynamics of teamwork, as it will help us to align opinions and decide on a common line of action.
        </p>

        <span className='h6 d-block font-weight-bold mt-4 mb-2'>HOW LONG</span>
        <div className='row mb-5'>
          <div className='col-md-6 col-lg-5 col-xl-2'>
            <button type='button' className='btn btn-link mb-3 p-0' onClick={() => { setVisible(true); }}>
              <img className='img-fluid rounded-3' src={require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg")} alt='Innovation focus image thumbnail' />
            </button>

            {/* {visible ? <Lightbox image={require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg")} title="Image Title" /> : null} */}

            <Viewer
              visible={visible}
              onClose={() => { setVisible(false); } }
              onMaskClick={() => {}}
              rotatable={false}
              downloadable={false}
              changeable={false}
              scalable={false}
              images={[{src: require("../../assets/img/Redefine-Challenge_Virtual-Hackathon.jpg"), alt: 'Innovation focus image'}]}
            />
          </div>

          <div className='col-md-6 col-lg-7 col-xl-6'>
            <p>
              5 minutes
            </p>
          </div>
        </div>

        <h2 className='h1 mb-4'>The process</h2>

        <p>Each individual of the team has three votes for each of these categories. She can decide to give three points for one element or divide her votes.</p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 1 (in blue): IMPORTANCE</span>

        <p>
          Each person decides which focus is more important for her.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 2 (in red): INFLUENCE</span>

        <p>
          Each person votes for the focus that they think that they have more influence to change things.
        </p>

        <span className='h6 d-block font-weight-bold mb-2'>STEP 3 (in green): INNOVATION</span>

        <p>
          Each person votes for the focus which solution she thinks could be more innovative.
        </p>

        <p>
          At the end of the voting, the group decides qualitatively or/and quantitatively which innovation focus they want to select to continue the process. 
        </p>
      </div>
    </Page>
  );
}